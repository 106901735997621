<template>
    <div>
        <div class="explore">
            <div class="text-h5 font-weight-bold mb-5">{{ $t('ExploreMarket.Title') }}</div>
            <div v-if="newsil8n == 'en'">
                <v-card elevation="0" v-for="(item, index) in enNewsList" :key="index" :href="item.link" target="_blank"
                    class="d-none d-sm-flex justify-space-between pa-6 mb-6">
                    <div class="mr-6 pt-3" style="width:calc(100% - 224px)">
                        <div class="text-h6 text-truncate">{{ item.title }}</div>
                        <div class="text-clamp grey--text mt-4">{{ item.content }}</div>
                        <div class="grey--text text-body-2 mt-2">{{ item.date }}</div>
                    </div>
                    <!-- <div>
                    <img contain width="200" height="140" :src="item.imgSrc" class="rounded" v-if="imgSrc">
                </div> -->
                </v-card>

                <v-card elevation="0" v-for="(item, index) in enNewsList" :key="'mobile' + index" :href="item.link"
                    target="_blank" class="d-block d-sm-none pa-6 mb-6">
                    <!-- <v-img contain width="100%" :src="item.imgSrc" class="rounded" v-if="imgSrc"></v-img> -->
                    <div class="pt-3">
                        <div class="text-body-1 font-weight-bold text-truncate">{{ item.title }}</div>
                        <div class="text-body-2 text-clamp grey--text mt-4">{{ item.content }}</div>
                        <div class="grey--text text-body-2 mt-2">{{ item.date }}</div>
                    </div>
                </v-card>
            </div>

            <div v-if="newsil8n == 'ja'">
                <v-card elevation="0" v-for="(item, index) in jaNewsList" :key="index" :href="item.link" target="_blank"
                    class="d-none d-sm-flex justify-space-between pa-6 mb-6">
                    <div class="mr-6 pt-3" style="width:calc(100% - 224px)">
                        <div class="text-h6 text-truncate">{{ item.title }}</div>
                        <div class="text-clamp grey--text mt-4">{{ item.content }}</div>
                        <div class="grey--text text-body-2 mt-2">{{ item.date }}</div>
                    </div>
                    <!-- <div>
                    <img contain width="200" height="140" :src="item.imgSrc" class="rounded" v-if="imgSrc">
                </div> -->
                </v-card>

                <v-card elevation="0" v-for="(item, index) in jaNewsList" :key="'mobile' + index" :href="item.link"
                    target="_blank" class="d-block d-sm-none pa-6 mb-6">
                    <!-- <v-img contain width="100%" :src="item.imgSrc" class="rounded" v-if="imgSrc"></v-img> -->
                    <div class="pt-3">
                        <div class="text-body-1 font-weight-bold text-truncate">{{ item.title }}</div>
                        <div class="text-body-2 text-clamp grey--text mt-4">{{ item.content }}</div>
                        <div class="grey--text text-body-2 mt-2">{{ item.date }}</div>
                    </div>
                </v-card>
            </div>

            <div v-if="newsil8n == 'zh'">
                <v-card elevation="0" v-for="(item, index) in zhNewsList" :key="index" :href="item.link" target="_blank"
                    class="d-none d-sm-flex justify-space-between pa-6 mb-6">
                    <div class="mr-6 pt-3" style="width:calc(100% - 224px)">
                        <div class="text-h6 text-truncate">{{ item.title }}</div>
                        <div class="text-clamp grey--text mt-4">{{ item.content }}</div>
                        <div class="grey--text text-body-2 mt-2">{{ item.date }}</div>
                    </div>
                    <div>
                        <!-- <img contain width="200" height="140" :src="item.imgSrc" class="rounded" v-if="imgSrc"> -->
                    </div>
                </v-card>

                <v-card elevation="0" v-for="(item, index) in zhNewsList" :key="'mobile' + index" :href="item.link"
                    target="_blank" class="d-block d-sm-none pa-6 mb-6">
                    <!-- <v-img contain width="100%" :src="item.imgSrc" class="rounded" v-if="imgSrc"></v-img> -->
                    <div class="pt-3">
                        <div class="text-body-1 font-weight-bold text-truncate">{{ item.title }}</div>
                        <div class="text-body-2 text-clamp grey--text mt-4">{{ item.content }}</div>
                        <div class="grey--text text-body-2 mt-2">{{ item.date }}</div>
                    </div>
                </v-card>
            </div>

            <div v-if="newsil8n == 'ko'">
                <v-card elevation="0" v-for="(item, index) in korNewsList" :key="index" :href="item.link" target="_blank"
                    class="d-none d-sm-flex justify-space-between pa-6 mb-6">
                    <div class="mr-6 pt-3" style="width:calc(100% - 224px)">
                        <div class="text-h6 text-truncate">{{ item.title }}</div>
                        <div class="text-clamp grey--text mt-4">{{ item.content }}</div>
                        <div class="grey--text text-body-2 mt-2">{{ item.date }}</div>
                    </div>
                    <div>
                        <!-- <img contain width="200" height="140" :src="item.imgSrc" class="rounded" v-if="imgSrc"> -->
                    </div>
                </v-card>

                <v-card elevation="0" v-for="(item, index) in korNewsList" :key="'mobile' + index" :href="item.link"
                    target="_blank" class="d-block d-sm-none pa-6 mb-6">
                    <!-- <v-img contain width="100%" :src="item.imgSrc" class="rounded" v-if="imgSrc"></v-img> -->
                    <div class="pt-3">
                        <div class="text-body-1 font-weight-bold text-truncate">{{ item.title }}</div>
                        <div class="text-body-2 text-clamp grey--text mt-4">{{ item.content }}</div>
                        <div class="grey--text text-body-2 mt-2">{{ item.date }}</div>
                    </div>
                </v-card>
            </div>
            <v-pagination class="mt-10" color="#AC8250" v-model="params.current" :total-visible="10" :length="pageLength"
                @input="getlist" @next="nextPage" @prev="prevPage"></v-pagination>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { get } from "../../request/http.js";
import Footer from '../../components/Footer.vue';


export default {
    components: {
        Footer
    },
    mounted() {
        this.newsil8n = this.$i18n.locale
        this.getlist()
    },
    data: () => ({
        newsil8n: 'zh',
        params: {
            current: 1,
            pageSize: 20
        },
        pageNum: 0,
        pageLength: 0,
        newsList: null,
        dataList: [
            {
                link: 'https://reurl.cc/aaobMX',
                title: 'scRNA-seq helps confirm the feasibility of stem cell therapy for macular degeneration',
                content: 'Dr Gioele La Manno, researcher at EPFL’s Life Sciences Independent Research (ELISIR) program, said: “None of the proposed differentiation protocols for clinical trials have been scrutinized for long at the single-cell level — we know they can make retinal pigment cells, but How cells evolved to this state remains a mystery.”',
                date: '2023/01/29',
                // imgSrc: require('../../assets/images/news/news_03.png')
            },
            {
                link: 'https://reurl.cc/Z10dlg',
                title: 'Using umbilical cord blood stem cell therapy, the world’s first female AIDS “cured” appeared',
                content: 'Scientists have cured a woman infected with HIV using a “miraculous” breakthrough treatment of umbilical cord blood transplantation, researchers announced at a conference in Denver, Colorado, on the 15th, according to foreign media reports. This is the first woman ever to receive a stem cell transplant using HIV-fighting cells, and the third known case of a functional cure for HIV.',
                date: '2023/01/29',
                // imgSrc: require('../../assets/images/news/news_03.png')
            },
            {
                link: 'https://reurl.cc/065baM',
                title: 'Guoxi Stem Cell Clinical Therapeutic Cell Laboratory applied for the approval of cell preparation site in accordance with the “Administrative Measures for the Implementation or Use of Specific Medical Technology Inspection and Inspection of Medical Instruments”, and was approved by the Ministry of Health and Welfare.',
                content: 'Central News AgencyJanuary 18, 2023;Date: January 18, 2023;Company Name: Guoxi Stem Cell (6704);Subject: Guoxi Stem Cell Clinical Therapeutic Cell Laboratory applied for the accreditation case of the cell preparation site in accordance with the “Administrative Measures for the Implementation or Use of Specific Medical Technology Inspection and Inspection of Medical Instruments”, and was approved by the Ministry of Health and Welfare.',
                date: '2023/01/29',
                // imgSrc: require('../../assets/images/news/news_03.png')
            },
            {
                link: 'https://reurl.cc/91jAWv',
                title: 'UC San Diego awards $8 million to expand clinical trial of stem cell therapy',
                content: 'awarded $8 million to expand clinical trials of novel stem cell therapies. The CIRM Award will foster partnerships between academic and industry experts in San Diego to expedite clinical trials for patients with refractory diseases.',
                date: '2023/01/29',
                // imgSrc: require('../../assets/images/news/news_03.png')
            },
            {
                link: 'https://reurl.cc/mZVbzj',
                title: 'カリフォルニア大学サンディエゴ校、幹細胞療法の臨床試験を拡大するために800万ドルを授与',
                content: 'カリフォルニア大学サンディエゴ校のアルファ幹細胞クリニックは、新しい幹細胞療法の臨床試験を拡大するために 800 万ドルを授与しました。 CIRM アワードは、難治性疾患患者の臨床試験を促進するために、サンディエゴの学界と業界の専門家の間のパートナーシップを促進します。',
                date: '2023/01/29',
                // imgSrc: require('../../assets/images/news/news_03.png')
            },
            {
                link: 'https://reurl.cc/AyQWp8',
                title: '臍帯血幹細胞療法で、世界初の女性エイズ「完治」が登場',
                content: '海外メディアの報道によると、研究者らは 15 日、コロラド州デンバーで開催された会議で、臍帯血移植という画期的な治療法を用いて HIV に感染した女性を治癒させた、と発表した。 これは、HIV と闘う細胞を使用した幹細胞移植を受けた最初の女性であり、HIV の機能的治癒の既知の 3 例目です。',
                date: '2023/01/29',
                // imgSrc: require('../../assets/images/news/news_03.png')
            },
            {
                link: 'https://reurl.cc/lZqbl6',
                title: '細胞療法への挑戦 Formosa Plastics Biomedical Information Technology Co., Ltd. 最先端医療に注力',
                content: '精密医療ビジネスの機会を拡大するために、Formosa Plastics Biomedical、Xunlian、Advanced Medical は細胞療法の展開を加速させました。 Formosa Plastics Biomedical は、細胞治療の応用に楽観的で、Ivy Life Sciences の株式の半分以上を取得し、抗がんがん治療の中核技術プラットフォームである CIK 自己免疫キラー細胞医療技術 (製品名は、 to as IKC) は 3 カ国で特許を取得しており、非小細胞肺癌の最終段階である第 II 相ヒト臨床試験は、今年、協力病院と適応症の範囲を拡大する予定です。',
                date: '2023/01/29',
                // imgSrc: require('../../assets/images/news/news_03.png')
            },
            {
                link: 'https://reurl.cc/pZzb8l',
                title: '加州大学圣地亚哥分校拨款800万美元扩大干细胞治疗临床试验',
                content: '加州大学圣地亚哥分校阿尔法干细胞诊所拨款800万美元，用于扩大新型干细胞疗法的临床试验。CIRM奖将促进圣地亚哥学术和行业专家之间的合作关系，以加快对难治性疾病患者的临床试验。',
                date: '2023/01/29',
                // imgSrc: require('../../assets/images/news/news_03.png')
            },
            {
                link: 'https://reurl.cc/QWylm2',
                title: '利用脐带血干细胞疗法 全球首位女性艾滋病“治愈”者出现',
                content: '据外媒最新报道，研究人员15日在美国科罗拉多州丹佛举行的会议上宣布，科学家使用一种“神奇的”脐带血移植的突破性治疗法治愈了一名艾滋病病毒（HIV）女性感染者。这是有史以来第一位接受干细胞移植并使用抗HIV细胞的女性，也是第三位已知的HIV功能性治愈的病例。',
                date: '2023/01/29',
                // imgSrc: require('../../assets/images/news/news_03.png')
            }
        ],
        enNewsList: [
            // {
            //     link: 'https://reurl.cc/91jAWv',
            //     title: 'UC San Diego awards $8 million to expand clinical trial of stem cell therapy',
            //     content: 'awarded $8 million to expand clinical trials of novel stem cell therapies. The CIRM Award will foster partnerships between academic and industry experts in San Diego to expedite clinical trials for patients with refractory diseases.',
            //     date: '2023/01/29',
            //     // imgSrc: require('../../assets/images/news/news_03.png')
            // },
        ],
        jaNewsList: [
            // {
            //     link: 'https://reurl.cc/lZqbl6',
            //     title: '細胞療法への挑戦 Formosa Plastics Biomedical Information Technology Co., Ltd. 最先端医療に注力',
            //     content: '精密医療ビジネスの機会を拡大するために、Formosa Plastics Biomedical、Xunlian、Advanced Medical は細胞療法の展開を加速させました。 Formosa Plastics Biomedical は、細胞治療の応用に楽観的で、Ivy Life Sciences の株式の半分以上を取得し、抗がんがん治療の中核技術プラットフォームである CIK 自己免疫キラー細胞医療技術 (製品名は、 to as IKC) は 3 カ国で特許を取得しており、非小細胞肺癌の最終段階である第 II 相ヒト臨床試験は、今年、協力病院と適応症の範囲を拡大する予定です。',
            //     date: '2023/01/29',
            //     // imgSrc: require('../../assets/images/news/news_03.png')
            // },
        ],
        zhNewsList: [
            // {
            //     link: 'https://reurl.cc/QWylm2',
            //     title: '利用脐带血干细胞疗法 全球首位女性艾滋病“治愈”者出现',
            //     content: '据外媒最新报道，研究人员15日在美国科罗拉多州丹佛举行的会议上宣布，科学家使用一种“神奇的”脐带血移植的突破性治疗法治愈了一名艾滋病病毒（HIV）女性感染者。这是有史以来第一位接受干细胞移植并使用抗HIV细胞的女性，也是第三位已知的HIV功能性治愈的病例。',
            //     date: '2023/01/29',
            //     // imgSrc: require('../../assets/images/news/news_03.png')
            // }
        ],
        korNewsList: [

        ]
    }),
    watch: {
        '$i18n.locale'(newil8n) {
            this.newsil8n = newil8n;

        }
    },
    methods: {
        // 分页下一页
        nextPage: function () {
            this.params.current + 1
        },
        // 分页上一页
        prevPage: function () {
            this.params.current - 1
        },
        getlist: async function () {
            await get(`https://capi.supercells.pro/app/api/market/pc/list?query={"current": ${this.params.current}, "pageSize": ${this.params.pageSize}}`, this.params).then(res => {
                if (res.status == 200) {
                    this.pageNum = res.data.data.current;
                    this.pageLength = Math.ceil(res.data.data.total / this.params.pageSize);
                    this.newsList = res.data.data.data;
                    res.data.data.data.forEach((item) => {
                        this.zhNewsList.push({
                            link: item.url,
                            title: item.title,
                            content: item.keywords,
                            data: item.createdAt
                        })
                        this.enNewsList.push({
                            link: item.urlEn,
                            title: item.titleEn,
                            content: item.keywordsEn,
                            data: item.createdAt
                        })
                        this.jaNewsList.push({
                            link: item.urlJap,
                            title: item.titleJap,
                            content: item.keywordsJap,
                            data: item.createdAt

                        })
                        this.korNewsList.push({
                            link: item.urlKor,
                            title: item.titleKor,
                            content: item.keywordsKor,
                            data: item.createdAt
                        })
                    });

                }
            })
        }
    },
}
</script>

<style>
.explore {
    min-height: calc(100vh - 289px);
    padding: 80px 120px;
    background: #FCF9F6;
}

.text-clamp {
    word-break: break-all !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

@media screen and (max-width: 960px) {
    .explore {
        padding: 30px 24px;
    }
}
</style>
  